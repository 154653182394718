<template>
  <div class="c-app modal-vue miner-info support">
    <CWrapper class="bg-bg-gray pt-2">
      <div class="c-title pb-4">{{ $t("Tickets") }}</div>
      <div class="support-mobile">
        <table class="table">
          <thead>
            <th>№</th>
            <th>{{ $t("Topic") }}</th>
            <th>{{ $t("Message") }}</th>
            <th>{{ $t("Date of creation") }}</th>
            <th>{{ $t("Date of change") }}</th>
            <th>{{ $t("Status") }}</th>
            <th></th>
          </thead>
          <tbody>
            <tr
              v-for="(obj, i) in tickets.data"
              class="cursor-pointer"
              :key="i"
              @click="goToTicket(obj.id)"
            >
              <td>{{ obj.id }}</td>
              <td>{{ obj.subject }}</td>
              <td>{{ obj.body }}</td>
              <td>{{ format(obj.created_at) }}</td>
              <td>{{ format(obj.updated_at) }}</td>
              <td>
                <span v-bind:class="statusClass(obj.status)">
                  {{ obj.status }}
                </span>
              </td>
              <td>
                <img
                  src="@/assets/icons/Arrow-right.svg"
                  class="cursor-pointer m-0 p-0 btn"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </CWrapper>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Support",
  data() {
    return {
      page: parseInt(this.$route.params.id),
      items: [],
      statusCls: [
        { key: "open", value: "status-open" },
        { key: "inprogress", value: "status-progress" },
        { key: "done", value: "status-done" },
      ],
    };
  },
  computed: {
    ...mapState("miners", ["getTickets", "allFarms", "tickets"]),
  },
  watch: {
    "$store.state.miners.userSearchText": {
      handler: function (e) {
        this.searchTickets(e);
      },
      deep: true,
    },
  },
  created() {
    this.curPage();
    this.$store.dispatch("miners/GET_TICKETS");
  },
  methods: {
    statusClass(key) {
      return this.statusCls.find((x) => x.key === key).value;
    },
    searchTickets(subject) {
      this.$store.dispatch("miners/SEARCH_TICKET", subject);
    },
    curPage() {
      this.$emit("curPage", "ticket");
      if (this.page === undefined) {
        this.page = 1;
      }
    },
    mappedTickets(res) {
      return res.data;
    },
    format(value) {
      var d = new Date(value);
      return d.toLocaleDateString();
    },
    goToTicket(id) {
      this.$router.push("/Tickets/" + id);
    },
  },
};
</script>

<style scoped lang="sass">
.miners
  &-well, &-danger, &-total
    padding: 6px 16px
    border-radius: 8px
    font-weight: 500
    font-size: 12px
    color: white

  &-well
    background: #78CD5A

  &-danger
    background: #F42A4E

  &-total
    background: #C2C2C2
</style>
